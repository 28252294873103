import { createGlobalStyle, DefaultTheme } from 'styled-components';
import Favorit from '@fonts/favorit.otf';
import Suisse from '@fonts/suisse.otf';

export const theme: DefaultTheme = {
  font: {
    primary: 'Suisse',
    secondary: 'Favorit'
  },
  color: {
    rsvpBlue: '#86a6ab',
    brown: '#231C07',
    white: '#f4f4f2',
    black: '#010101',
    liveLink: '#8b7153',
    inputBg: '#d3d3d3'
  },
  device: {
    mobileS: '(max-width: 320px)',
    mobileM: '(max-width: 375px)',
    mobileL: '(max-width: 425px)',
    mobileXL: '(max-width: 520px)',
    tablet: '(max-width: 768px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1440px)',
    desktop: '(max-width: 2560px)'
  },
  underlineOffset: '4px',
  hoverTransition: 0.25,
  hoverOpacity: 0.55
};

export const GlobalStyles = createGlobalStyle`

/* Font used for any Integers */
@font-face {
  font-family: 'Favorit';
  src: local('Favorit'), url('${Favorit}') format('opentype');
}

/* Front use for all Text, and all text should be capitalized */
@font-face {
  font-family: 'Suisse';
  src: local('Suisse'), url('${Suisse}') format('opentype');
}

* {
  scrollbar-width: none;
}

html, body {
  height: 100vh;
  width: 100vw;
  color: ${theme.color.white};
  font-family: ${theme.font.primary};
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

input, textarea {
  font-family: ${theme.font.primary};
  font-size: 1.25rem;
  letter-spacing: 1.75px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.inputBg};
  border: 0.5px solid ${({ theme }) => theme.color.black};
  resize: none;
}

input:focus, textarea:focus {
  outline: none !important;
  border: 0.5px solid ${({ theme }) => theme.color.liveLink};
}

`;
