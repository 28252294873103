import React from 'react';
import { theme, GlobalStyles } from '@theme';
import { ThemeProvider } from 'styled-components';
import type { GatsbyBrowser } from 'gatsby';
import app from 'gatsby-plugin-firebase-v9.0';
import { getAnalytics, logEvent } from 'firebase/analytics';
import "the-new-css-reset"

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider theme={theme}>
    <>
      {element}
      <GlobalStyles />
    </>
  </ThemeProvider>
);

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {

  const analytics = getAnalytics(app);
  logEvent(analytics, 'page_view', {
    page_path: location.pathname
  });
};